<template>
	<div class="createParks">
		<div class="main-filter">
			<el-form inline :model="formInline" class="demo-form-inline">
				<el-form-item label="省：">
					<el-select
						v-model="formInline.economize"
						:disabled="userInfo.regionCode && userInfo.regionCode.length >= 2"
						placeholder="请选择省"
						@change="changeEconomize($event, 1)"
					>
						<el-option v-for="item in economizeList" :key="item.id" :label="item.title" :value="item.code"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="市：">
					<el-select
						v-model="formInline.market"
						:disabled="userInfo.regionCode && userInfo.regionCode.length >= 4"
						placeholder="请选择市"
						@change="changeEconomize($event, 2)"
					>
						<el-option v-for="item in marketList" :key="item.id" :label="item.title" :value="item.code"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="区县：">
					<el-select
						v-model="formInline.district"
						:disabled="userInfo.regionCode && userInfo.regionCode.length >= 6"
						placeholder="请选择区县"
						@change="changeEconomize($event, 3)"
					>
						<el-option v-for="item in districtList" :key="item.id" :label="item.title" :value="item.code"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="乡镇：">
					<el-select
						v-model="formInline.townCode"
						:disabled="userInfo.regionCode && userInfo.regionCode.length >= 9"
						placeholder="请选择"
						@change="changeEconomize($event, 4)"
					>
						<el-option v-for="item in townList" :key="item.id" :label="item.title" :value="item.code"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="村：">
					<el-select
						v-model="formInline.villageCode"
						:disabled="userInfo.regionCode && userInfo.regionCode.length >= 12"
						placeholder="请选择"
						
					>
						<el-option v-for="item in villageList" :key="item.id" :label="item.title" :value="item.code"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="onSearch" icon="el-icon-search">搜索</el-button>
				</el-form-item>
			</el-form>
		</div>
		<div class="main-content">
			<div class="main-content-title">
				<p>共有 {{ total }} 条结果</p>
			</div>
			<Table
				:loading="loading"
				:table-data="tableData"
				:total="total"
				:table-head="tableHead"
				:current-page.sync="queryData.current"
				:page-size.sync="queryData.size"
				:operation-column-width="100"
				:is-show-selection="false"
				:showOperation="true"
				@change-page="getList"
				:tableHeight="tableHeight"
			>
				<!-- 插槽操作栏固定属性operation -->
				<template #operation="{ scope }">
					<el-button @click="modifyParkInfo(scope.row)" size="mini" type="primary"> 查看 </el-button>
				</template>
			</Table>
		</div>
	</div>
</template>

<script>
export default {
	name: 'familyInfo',
	components: {
		Table: () => import('@/components/Table/table'),
	},
	data() {
		return {
			formInline: {
				economize: '',
				market: '',
				district: '',
				townCode: '',
				villageCode: '',
			},
			loading: false,
			queryData: {
				current: 1,
				size: 10,
			},
			total: 0,
			tableData: [],
			tableHead: [
				{
					label: '家庭编号',
					prop: 'identifier',
					formatter: (row) => {
						return row.identifier || '-';
					},
				},
				{
					label: '家庭成员',
					prop: 'personList',
					formatter: (row) => {
						return row.personList?.map((v) => v.title).join(',') || '-';
					},
				},
				{
					label: '联系电话',
					prop: 'personList',
					formatter: (row) => {
						return (row.personList && row.personList[0].code) || '-';
					},
				},
				{
					label: '乡镇/街道街道名称',
					prop: 'regionDtoList',
					formatter: (row) => {
						return row.regionDtoList && row.regionDtoList.length > 3 ? row.regionDtoList[3].title : '-';
					},
				},
				{
					label: '村委会名称',
					prop: 'regionDtoList',
					formatter: (row) => {
						return row.regionDtoList && row.regionDtoList.length > 4 ? row.regionDtoList[4].title : '-';
					},
				},
				{
					label: '创建时间',
					prop: 'createTime',
					formatter: (row) => {
						return row.createTime || '-';
					},
				},
			],
			options: [],
			economizeList: [],
			marketList: [],
			districtList: [],
			townList: [],
			villageList: [],
			userInfo: {},
		};
	},
	computed: {
		tableHeight() {
			return window.innerHeight - 300;
		},
	},
	mounted() {
		this.getEconomizeList();
		this.userInfo = JSON.parse(sessionStorage.getItem('userInfo')) || {};
		switch (this.userInfo.regionCode.length) {
			case 2:
				this.formInline.economize = this.userInfo.chinaRegionDtoList[0].code != '0' ? this.userInfo.chinaRegionDtoList[0].code : null;
				this.changeEconomize(this.formInline.economize, 1);
				break;
			case 4:
				this.formInline.economize = this.userInfo.chinaRegionDtoList[0].code != '0' ? this.userInfo.chinaRegionDtoList[0].code : null;
				this.changeEconomize(this.formInline.economize, 1);
				this.formInline.market = this.userInfo.chinaRegionDtoList[1].code;
				this.changeEconomize(this.formInline.market, 2);
				break;
			case 6:
				this.formInline.economize = this.userInfo.chinaRegionDtoList[0].code != '0' ? this.userInfo.chinaRegionDtoList[0].code : null;
				this.changeEconomize(this.formInline.economize, 1);
				this.formInline.market = this.userInfo.chinaRegionDtoList[1].code;
				this.changeEconomize(this.formInline.market, 2);
				this.formInline.district = this.userInfo.chinaRegionDtoList[2].code;
				this.changeEconomize(this.formInline.district, 3);
				break;
			case 9:
				this.formInline.economize = this.userInfo.chinaRegionDtoList[0].code != '0' ? this.userInfo.chinaRegionDtoList[0].code : null;
				this.changeEconomize(this.formInline.economize, 1);
				this.formInline.market = this.userInfo.chinaRegionDtoList[1].code;
				this.changeEconomize(this.formInline.market, 2);
				this.formInline.district = this.userInfo.chinaRegionDtoList[2].code;
				this.changeEconomize(this.formInline.district, 3);
				this.formInline.townCode = this.userInfo.chinaRegionDtoList[3].code;
				this.changeEconomize(this.userInfo.regionCode, 4);
				break;
			case 12:
				this.formInline.economize = this.userInfo.chinaRegionDtoList[0].code != '0' ? this.userInfo.chinaRegionDtoList[0].code : null;
				this.changeEconomize(this.formInline.economize, 1);
				this.formInline.market = this.userInfo.chinaRegionDtoList[1].code;
				this.changeEconomize(this.formInline.market, 2);
				this.formInline.district = this.userInfo.chinaRegionDtoList[2].code;
				this.changeEconomize(this.formInline.district, 3);
				this.formInline.townCode = this.userInfo.chinaRegionDtoList[3].code;
				this.changeEconomize(this.userInfo.regionCode, 4);
				this.formInline.villageCode = this.userInfo.chinaRegionDtoList[4].code;
				break;
			default:
				break;
		}
		this.getList();
	},
	methods: {
		// 获取省数据
		getEconomizeList() {
			this.$http
				.get(this.api['ChinaRegions#index'].href)
				.then((res) => {
					if (res.data && res.data.success) {
						this.economizeList = res.data.collection || [];
						this.economizeList.unshift({
							title: '全部',
							code: '',
						});
					}
				})
				.catch((e) => {});
		},
		// 获取市/区县
		changeEconomize(data, type) {
			switch (type) {
				case 1:
					this.formInline.market = null;
					this.formInline.district = null;
					this.formInline.townCode = null;
					this.formInline.villageCode = null;
					this.marketList = [];
					this.districtList = [];
					this.townList = [];
					this.villageList = [];
					if (!data || data == '') return;
					this.$http
						.get(this.api['ChinaRegions#index'].href, { params: { code: data } })
						.then((res) => {
							if (res.data && res.data.success) {
								this.marketList = res.data.collection || [];
								this.marketList.unshift({
									title: '全部',
									code: '',
								});
							}
						})
						.catch((e) => {});
					break;
				case 2:
					this.formInline.district = null;
					this.formInline.townCode = null;
					this.formInline.villageCode = null;
					this.districtList = [];
					this.townList = [];
					this.villageList = [];
					if (!data || data == '') return;
					this.$http
						.get(this.api['ChinaRegions#index'].href, { params: { code: data } })
						.then((res) => {
							if (res.data && res.data.success) {
								this.districtList = res.data.collection || [];
								this.districtList.unshift({
									title: '全部',
									code: '',
								});
							}
						})
						.catch((e) => {});
					break;
				case 3:
					this.formInline.townCode = null;
					this.formInline.villageCode = null;
					this.townList = [];
					this.villageList = [];
					if (!data || data == '') return;
					this.$http
						.get(this.api['ChinaRegions#index'].href, { params: { code: data } })
						.then((res) => {
							if (res.data && res.data.success) {
								this.townList = res.data.collection || [];
								this.townList.unshift({
									title: '全部',
									code: '',
								});
							}
						})
						.catch((e) => {});
					break;
				case 4:
					this.formInline.villageCode = null;
					this.villageList = [];
					if (!data || data == '') return;
					this.$http
						.get(this.api['ChinaRegions#index'].href, { params: { code: data } })
						.then((res) => {
							if (res.data && res.data.success) {
								this.villageList = res.data.collection || [];
								this.villageList.unshift({
									title: '全部',
									code: '',
								});
							}
						})
						.catch((e) => {});
					break;
				default:
					break;
			}
		},
		// 查询条件
		onSearch() {
			this.queryData.current = 1;
			this.getList();
		},

		//获取列表
		getList() {
			this.loading = true;
			const { economize, market, district, townCode, villageCode } = this.formInline;
			let code =
				economize && !market && !district && !townCode && !villageCode
					? economize
					: economize && market && !district && !townCode && !villageCode
					? market
					: economize && market && district && !townCode && !villageCode
					? district
					: economize && market && district && townCode && !villageCode
					? townCode
					: economize && market && district && townCode && villageCode
					? villageCode
					: null;
			let data = {
				regionCode: code,
				...this.queryData,
			};
			this.$http
				.get(this.api['Households#index'].href, { params: data })
				.then((res) => {
					if (res.data && res.data.success) {
						this.tableData = res.data.collection || [];
						this.total = res.data.pagination.totalItems || 0;
						this.loading = false;
					}
				})
				.catch((e) => {
					this.loading = false;
				});
		},
		modifyParkInfo(row) {
			this.$router.push({
				path: '/staticTotalView/familyInfo/showDetails',
				query: {
					familyNumber: row.identifier,
					personList: row.personList?.length > 0 ? JSON.stringify(row.personList) : [],
				},
			});
		},
	},
};
</script>

<style lang="scss" scoped>
.main-content {
	&-title {
		line-height: 48px;
		display: flex;
		justify-content: space-between;

		.el-button {
			line-height: 32px;
			padding: 0 16px;
			height: 32px;
			margin-top: 8px;
		}
	}
}
.demo-form-inline {
	/deep/ .el-select {
		width: 120px !important;
		.el-input {
			width: 120px !important;
			.el-input__inner {
				width: 120px !important;
			}
		}
	}
	/deep/ .el-input {
		width: 160px;
	}
}
.el-button--warning {
	background-color: #38b4f2;
	border-color: #38b4f2;
	color: #fff;
}
</style>
